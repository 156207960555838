import "./ColClaim.css";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../GlobalStateContext";

const ColName = ({ text, url }) => {
  const splitStringAfterFirstWord = (str) => {
    const firstSpaceIndex = str.indexOf(" ");
    if (firstSpaceIndex === -1) {
      return [str];
    }
    const firstWord = str.slice(0, firstSpaceIndex);
    const restOfString = str.slice(firstSpaceIndex + 1);
    return [firstWord, restOfString];
  };

  const [firstWord, restOfString] = splitStringAfterFirstWord(text);

  return (
    <div>
      <span className="shadowed">{firstWord}</span>
      {restOfString && <br />}
      <span className="shadowed">{restOfString}</span>
    </div>
  );
};

const ColClaim = ({
  colName,
  elevation,
  latitude,
  longitude,
  claimDate,
  colId,
  activityUrl,
  activityCount,
  colClubId,
}) => {
  const { user } = useGlobalState();

  const navigate = useNavigate();

  const goToCol = (colId) => {
    navigate(`/users/${user.user.id}/cols/${colId}`);
  };

  const getMapboxStaticMapUrl = (colClubId) => {
    return `https://colquest-django-statics.s3.eu-west-3.amazonaws.com/col_thumbnails/${colClubId}.jpg`;
  };

  const getBackgroundColorFromElevation = (elevation) => {
    if (elevation < 500) {
      // Blue
      return "#3589e9";
    }
    if (elevation < 1000) {
      // Green
      return "#44b554";
    }
    if (elevation < 2000) {
      // Yellow
      return "#feea01";
    }
    // Red
    return "#fa1e37";
  };
  return (
    <div onClick={() => goToCol(colId)} className="colClaim">
      <div className="colClaimBox">
        {/* <div className="colIndex">#{colIndex}</div> */}
        <div
          className="colImage"
          style={{
            backgroundColor: getBackgroundColorFromElevation(elevation),
          }}
        >
          <img
            alt="satellite"
            src={getMapboxStaticMapUrl(colClubId)}
          />
        </div>
        <div className="colName">
          <ColName text={colName} url={activityUrl} />
        </div>
        <div className="colMetrics">
          <div className="colMetric">
            <span style={{ color: getBackgroundColorFromElevation(elevation) }}>
              &#x25B2;
            </span>
            {elevation}m
          </div>
          <div className="colMetric">
            {Moment(claimDate).format("YYYY-MM-DD")}
          </div>
          <div className="colMetric">
            <span style={{ fontSize: ".9rem" }}>x</span>
            <span style={{ fontWeight: 900 }}>{activityCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColClaim;
