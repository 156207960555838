import React, { useState, useEffect } from "react";
import buttonIcon from "../assets/btn_strava_connectwith_orange.svg";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import "./StravaLogin.css";

const StravaLogin = () => {
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const userId = Cookies.get("colquest_user_id");
    if (userId) {
      navigate(`/users/${userId}`);
    }

    if (query.get("authError")) {
      setAuthError(
        `<b>Something went wrong during sign-in.</b><br>Please try again later.`,
      );

      if (query.get("authError") === "denied") {
        setAuthError(
          `<b>Access denied.</b> You did not allow ColQuest to connect to your Strava Account.`,
        );
      }

      if (query.get("authError") === "missingScopes") {
        setAuthError(`<b>Please grant all requested permissions to sign-in.</b><br /><br />
        ColQuest needs permissions to read your activities to show your col&#x2022;lection.<br />
        It also needs write permissions to update your activity description with your latest climbs.
      `);
      }
    }
  }, [navigate, query]);

  const handleLogin = () => {
    const callbackUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/auth/strava/`;
    const stravaClientId = process.env.REACT_APP_STRAVA_CLIENT_ID;

    const targetUrl = `https://www.strava.com/oauth/authorize?approval_prompt=auto&scope=read,profile:read_all,activity:read_all,activity:write&client_id=${stravaClientId}&response_type=code&redirect_uri=${encodeURIComponent(
      callbackUrl,
    )}`;
    window.location.href = targetUrl;
  };

  return (
    <>
      <div className="login">
        <span className="bigLogo">▲</span>
        <span className="logoText">ColQuest</span>
        <span className="tagline">Let's grow your col&#x2022;lection</span>
        <button onClick={handleLogin}>
          <img src={buttonIcon} alt="Button Icon" />
        </button>
        <div className="definition">
          {authError ? (
            <p
              dangerouslySetInnerHTML={{ __html: authError }}
              style={{ color: "red" }}
            ></p>
          ) : (
            <p>
              <b>Col (🇫🇷 noun)</b>
              <br />
              <br />
              1. (<b>Cycling</b>) The ultimate battleground where cyclists
              conquer heights, test their mettle, and become legends.
              <br />
              <br />
              2. (Geography) A navigable route through a mountain range or over
              a ridge.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default StravaLogin;
