// Obviously we need react and some goodies
import React, { useEffect, useState, useRef } from "react";

// Here's our Mapbox imports
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import "mapbox-gl/dist/mapbox-gl.css";
import polyline from "@mapbox/polyline";
// Import styling
import "./MapBoxComponent.css";
import ActivityList from "./ActivityList";
import { useNavigate } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MAPBOX_STYLE_URL =
  "mapbox://styles/alafanechere/clwxpgm0h01aj01qx3kym9lol";
const HIGHLIGHTED_SOURCE_ID = "highlightedCols";
const OTHER_COLS_SOURCE_ID = "otherCols";
const ACTIVITY_PATHS_SOURCE_ID = "activityPaths";

// LAYER CONFIGS
const defaultHighlightedLayerConfig = {
  type: "symbol",
  source: HIGHLIGHTED_SOURCE_ID,
  layout: {
    "text-field": ["get", "name"],
    "text-font": ["Jaro Regular", "Arial Unicode MS Bold"],
    "text-offset": [0.7, 0.7],
    "icon-image": "triangle-filled",
    "text-variable-anchor": ["top", "bottom", "left", "right"],
  },
};

const otherColsLayerConfig = {
  id: "otherColsLayer",
  type: "symbol",
  source: OTHER_COLS_SOURCE_ID,
  layout: {
    "text-field": ["step", ["zoom"], "", 11, ["get", "name"]],
    "text-font": ["Jaro Regular", "Arial Unicode MS Bold"],
    "text-offset": [0, 0.7],
    "icon-image": "triangle-filled",
    "text-variable-anchor": ["top", "bottom", "left", "right"],
  },
  paint: {
    "text-halo-width": 0,
    "text-halo-blur": 0,
    "text-halo-color": "gray",
  },
};

const activityPathsLayerConfig = {
  id: "activityPaths",
  type: "line",
  source: ACTIVITY_PATHS_SOURCE_ID,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#fc4c02",
    "line-width": 4,
    "line-blur": 0,
  },
};

const activityPathsSubLayerConfig = {
  id: "activitySubLayerPaths",
  type: "line",
  source: ACTIVITY_PATHS_SOURCE_ID,
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "yellow",
    "line-width": 8,
    "line-blur": 3,
  },
};

const createLayerConfig = (id, filter, color) => ({
  ...defaultHighlightedLayerConfig,
  id,
  filter,
  paint: {
    "text-halo-width": 30,
    "text-halo-blur": 10,
    "text-halo-color": color,
  },
});

const colSmallLayerConfig = createLayerConfig(
  "colSmallLayer",
  ["<=", "elevation", 500],
  "#00adff",
);
const colMidLayerConfig = createLayerConfig(
  "colMidLayer",
  [">", "elevation", 500],
  "chartreuse",
);
const colLargeLayerConfig = createLayerConfig(
  "colLargeLayer",
  [">", "elevation", 1000],
  "#feea01",
);
const colXLargeLayerConfig = createLayerConfig(
  "colXLargeLayer",
  [">", "elevation", 2000],
  "#fa1e37",
);

const clickableSymbolLayers = [
  "colMidLayer",
  "colSmallLayer",
  "colLargeLayer",
  "colXLargeLayer",
  "otherColsLayer",
];



const MapboxComponent = ({
  highlightedCols,
  otherCols,
  initialCol,
  userId,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const navigate = useNavigate();

  const [zoom] = useState(10);
  const [activities, setActivities] = useState([]);
  const [focusedActivity, setFocusedActivity] = useState(null);
  const [focusedActivityPath, setFocusedActivityPath] = useState(null);

  const [focusedCol, setFocusedCol] = useState(initialCol);

  useEffect(() => {

    if (mapContainer.current && !map.current) {
      map.current = new mapboxgl.Map(
        {
          container: mapContainer.current,
          style: MAPBOX_STYLE_URL,
          center: [
            initialCol.geometry.coordinates[0],
            initialCol.geometry.coordinates[1],
          ],
          zoom: zoom,
          pitch: 0,
        },
        [],
      );
      map.current.addControl(new mapboxgl.GeolocateControl({fitBoundsOptions: {maxZoom: 12}}), "top-right");
      map.current.addControl(new mapboxgl.FullscreenControl(), "top-right");
      map.current.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        }),
        "top-left"
      );
      //map.current.addControl(new ColToggle(), "top-right");

    }
  });

  useEffect(() => {
    map.current &&
      map.current.on("load", () => {
        // Add our navigation control (the +/- zoom buttons)
        if (!map.current.getSource(HIGHLIGHTED_SOURCE_ID)) {
          map.current.addSource(HIGHLIGHTED_SOURCE_ID, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: highlightedCols,
            },
          });
        }

        if (!map.current.getSource(OTHER_COLS_SOURCE_ID)) {
          map.current.addSource(OTHER_COLS_SOURCE_ID, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: otherCols,
            },
          });
        }

        if (map.current.getSource(ACTIVITY_PATHS_SOURCE_ID)) {
          map.current
            .getSource(ACTIVITY_PATHS_SOURCE_ID)
            .setData(focusedActivityPath);
        } else {
          map.current.addSource(ACTIVITY_PATHS_SOURCE_ID, {
            type: "geojson",
            data: focusedActivityPath,
          });
          map.current.addLayer(activityPathsSubLayerConfig);
          map.current.addLayer(activityPathsLayerConfig);
        }

        if (!map.current.getLayer("otherColsLayer")) {
          map.current.addLayer(otherColsLayerConfig);
        }
        if (!map.current.getLayer("colSmallLayer")) {
          map.current.addLayer(colSmallLayerConfig);
        }

        if (!map.current.getLayer("colMidLayer")) {
          map.current.addLayer(colMidLayerConfig);
        }

        if (!map.current.getLayer("colLargeLayer")) {
          map.current.addLayer(colLargeLayerConfig);
        }

        if (!map.current.getLayer("colXLargeLayer")) {
          map.current.addLayer(colXLargeLayerConfig);
        }

        clickableSymbolLayers.forEach((layer) => {
          map.current.on("click", layer, (e) => {
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: [layer],
            });
            if (!features.length) return;
            const feature = features[0];
            setFocusedCol(feature);

            map.current.flyTo({
              center: feature.geometry.coordinates,
              essential: true,
              zoom: zoom,
            });
            navigate(`/users/${userId}/cols/${feature.properties.id}`);
          });
        });
      });
  }, [
    focusedActivityPath,
    highlightedCols,
    initialCol,
    otherCols,
    zoom,
    userId,
    navigate,
  ]);

  useEffect(() => {
    const fetchActivitiesForCol = async (colId) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/activities?col_id=${colId}`,
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    };

    if (focusedCol) {
      fetchActivitiesForCol(focusedCol.properties.id)
        .then((data) => {
          setFocusedActivity(data[0]);
          setActivities(data);
        })
        .catch((error) => {
          console.error("Error fetching line data:", error);
        });
    }
  }, [focusedCol, initialCol, otherCols, zoom, userId]);

  useEffect(() => {
    if (focusedActivity && map.current) {
      const activityPath = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: polyline
            .decode(focusedActivity.summary_polyline)
            .map(([lat, lng]) => [lng, lat]),
        },
      };
      setFocusedActivityPath(activityPath);
      if (map.current.getSource(ACTIVITY_PATHS_SOURCE_ID)) {
        map.current.getSource(ACTIVITY_PATHS_SOURCE_ID).setData(activityPath);
      }
    }
  }, [focusedActivity]);

  return (
    <div className="mapPage">
      <div className="map-container" ref={mapContainer} />
      <>
        <ActivityList
          activities={activities}
          focusedActivity={focusedActivity}
          focusedCol={focusedCol}
          setFocusedActivity={setFocusedActivity}
        />
      </>
    </div>
  );
};

export default MapboxComponent;
