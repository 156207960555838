import { Triangle } from "react-loader-spinner";
import "./JoinClub.css";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const JoinClub = () => {
  const [loading, setLoading] = useState(false);
  const { userId } = useParams();
  const [checkExpired, setCheckExpired] = useState(false);

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const triggerMembershipCheck = async () => {
    setCheckExpired(false);
    await delay(3000);
    await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/check-club-membership`,
    );
    await delay(30000);
    setCheckExpired(true);
    setLoading(false);
  };

  const handleClick = () => {
    triggerMembershipCheck();
    setLoading(true);
  };

  return (
    <div className="joinClub">
      {loading ? (
        <>
          <Triangle
            height="50"
            width="50"
            color="#fc5200"
            ariaLabel="triangle-loading"
          />
          <p>Checking your membership...</p>
        </>
      ) : (
        <>
          <span className="emojiLogo">▲</span>
          <h1>One last thing...</h1>
          {checkExpired ? (
            <button onClick={handleClick}>Check membership again</button>
          ) : (
            <a
              href="https://www.strava.com/clubs/colquest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button onClick={handleClick}>Join our club on Strava</button>
            </a>
          )}

          <p>
            Let's build a community!
            <br />
            We'll also send you updates and share new features there.
          </p>
        </>
      )}
    </div>
  );
};

export default JoinClub;
