import UserColGrid from "./UserColGrid";
import UserMetrics from "./UserMetrics";
import "./UserDataPage.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const UserDataPage = ({ userColData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.endsWith("/stats")) {
      navigate("stats");
    }
  });

  return (
    <div className="userData">
      {userColData ? (
        <div>
          <section>
            <h2>Stats</h2>
            <UserMetrics userColData={userColData} />
          </section>
          <section>
            <h2>Col&#x2022;lection</h2>
            <UserColGrid userColData={userColData} />
          </section>
        </div>
      ) : null}
    </div>
  );
};
export default UserDataPage;
