import "./About.css";

const About = () => {
  return (
    <div className="userData">
      <h2>What is ColQuest?</h2>
      <p>
        ColQuest is a web application which connects to your Strava account to
        identify which <i>cols</i> (mountain passes in French) you've climbed on
        your bike. It's purely for fun and to help you keep track of your
        climbing achievements. You can see your stats, a map of the cols you've
        climbed, and maybe more.
      </p>
      <h2>Why do I have to join your Strava Club?</h2>
      <p>
        I don't want to request your email address to send you updates about
        ColQuest. I figured managing a Strava Club would be cooler to introduce
        a sense of community and share updates about the project there.
      </p>
      <h2>What does it take to claim a col?</h2>
      <p>
        Your legs, a bike, a col, a GPS device, and a Strava account. If you've
        climbed a col and recorded it on Strava, ColQuest will find it if the
        path of your activities goes over the col (within a 100 meter
        tolerance). Our database of cols contains <b>3909 cols</b> (only in
        France at the moment)
      </p>
      <h2>Is it free?</h2>
      <p>
        For you: <b>yes</b>. For me: <b>no</b>. I'm doing it for fun. Hosting
        fees are ~$50 a month. Feel free to consider donating if you like this
        application!
      </p>
      <div className="buy">
        <a
          href="https://www.buymeacoffee.com/alafanechere"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png"
            alt="Buy Me A Coffee"
          />
        </a>
      </div>
      <p>
        Sincerely,{" "}
        <span className="signature">
          <a
            href="https://www.strava.com/athletes/17003559"
            target="_blank"
            rel="noreferrer"
          >
            Augustin Lafanechère
          </a>
        </span>{" "}
        (feel free to DM me on Strava)
      </p>
    </div>
  );
};

export default About;
