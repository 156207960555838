import "./ActivityList.css";
import Moment from "moment";
import React, { useState } from "react";

const ActivityList = ({
  activities,
  focusedActivity,
  focusedCol,
  setFocusedActivity,
}) => {
  const [showActivities, setShowActivities] = useState(false);

  return (
    <>
      <div
        className="accordion"
        onClick={() => {
          if (activities.length > 0) {
            setShowActivities(!showActivities);
          }
        }}
      >
        <span className="accordionTitle accordionMember">
          {focusedCol.properties.name}
        </span>

        {activities.length === 1 ? (
          <button className="viewActivityButton">
            {showActivities ? "Hide activity" : `${activities.length} activity`}
          </button>
        ) : null}
        {activities.length > 1 ? (
          <button className="viewActivityButton">
            {showActivities
              ? "Hide activities"
              : `${activities.length} activities`}
          </button>
        ) : null}
        <span className="elevation accordionMember">
          &#x25B2; {focusedCol.properties.elevation}m
        </span>
      </div>
      {showActivities && activities.length > 0 ? (
        <div className="content">
          {activities.map((activity) => (
            <div
              className={`activity-square ${focusedActivity === activity ? "activity-square-active" : ""}`}
              key={activity.url}
              onClick={() => setFocusedActivity(activity)}
            >
              <div className="activity-top">
                {Moment(activity.start_date).format("YYYY-MM-DD ")}
              </div>
              <div className="activity-bottom">
                {Math.ceil(activity.distance_meters / 1000)}km
              </div>
              <div className="activity-title">{activity.name}</div>
              <div className="activity-url">
                <a href={activity.url}>View on Strava</a>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ActivityList;
