import { Triangle } from "react-loader-spinner";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <Triangle
        height="50"
        width="50"
        color="#fc5200"
        ariaLabel="triangle-loading"
      />
      <p className="info">Loading...</p>
    </div>
  );
};

export default Loading;
