import React, { useState, useMemo } from "react";
import "./UserColGrid.css";
import ColClaim from "./ColClaim";
import Grid from "@mui/material/Unstable_Grid2";

const UserColGrid = ({ userColData }) => {
  const [sortConfig, setSortConfig] = useState({
    key: "col.elevation",
    direction: "descending",
  });

  const sortedData = useMemo(() => {
    let sortableData = [...userColData];
    sortableData.sort((a, b) => {
      let aValue = a;
      let bValue = b;
      for (const key of sortConfig.key.split(".")) {
        aValue = aValue[key];
        bValue = bValue[key];
      }

      if (aValue < bValue) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableData;
  }, [userColData, sortConfig]);

  const handleSort = (key, direction) => {
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="userColGrid">
      <div className="sortBox">
        <div className="sortButton">&#9660;</div>
        <div
          className="sortButton"
          style={{ fontWeight: sortConfig.key === "col.elevation" ? 700 : 200 }}
          onClick={() => handleSort("col.elevation", "descending")}
        >
          elevation
        </div>
        |
        <div
          className="sortButton"
          style={{ fontWeight: sortConfig.key === "claim_date" ? 700 : 200 }}
          onClick={() => handleSort("claim_date", "descending")}
        >
          claim date
        </div>
        |
        <div
          className="sortButton"
          style={{
            fontWeight: sortConfig.key === "activity_count" ? 700 : 200,
          }}
          onClick={() => handleSort("activity_count", "descending")}
        >
          activities
        </div>
      </div>

      <Grid
        container
        spacing={{ xs: 1 }}
        columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
      >
        {sortedData.map((item, index) => (
          <Grid xs={1} key={index}>
            <ColClaim
              colIndex={sortedData.length - index}
              colId={item.col.id}
              colName={item.col.name}
              elevation={item.col.elevation}
              latitude={item.col.latitude}
              longitude={item.col.longitude}
              activityUrl={item.claim_activity.url}
              claimDate={item.claim_date}
              activityCount={item.activity_count}
              colClubId={item.col.club_id}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default UserColGrid;
