import "./Header.css";
import { useGlobalState } from "../GlobalStateContext";
import { useNavigate, NavLink } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";

const Header = () => {
  const { user } = useGlobalState();

  const [loadingData, setLoadingData] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const refreshStravaData = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${user.user.id}/activities`,
      {method: "POST"}
    );
    toggleMenu();
  };

  useEffect(() => {
    if (user) {
      setLoadingData(user.user.pending_activity_ingestion);
      setFirstName(user.user.first_name);
    }
  }, [user]);

  return (
    <>
      {loadingData ? (
        <div className="loadingFromStrava">Loading data from strava...</div>
      ) : null}
      <nav>
        <div className="leftHeader">
          <span
            className="smallLogoText"
            onClick={() => navigate(`/users/${user.user.id}`)}
          >
            <a
              href={user.profile_url}
              className="firstName"
              target="_blank"
              rel="noopener noreferrer"
            >
              {firstName}
            </a>
            's ColQuest
          </span>
          <span className={`emojiLogo ${loadingData ? "spinning" : ""}`}>
            ▲
          </span>
          {loadingData ? (
            <span className="loadingMessage">Loading data from strava...</span>
          ) : null}
        </div>
        <div className="rightHeader">
          <div className="hamburger" onClick={toggleMenu}>
            <FaBars size={20} />
          </div>
          <div
            className="mobileMenu"
            style={{ display: isOpen ? "flex" : "none" }}
          >
            <div className="hamburger" onClick={toggleMenu}>
              <FaBars size={20} />
            </div>
            <span className={`emojiLogo ${loadingData ? "spinning" : ""}`}>
              ▲
            </span>
            <NavLink
              onClick={toggleMenu}
              className={({ isActive }) =>
                isActive ? "activeNavButton navButton" : "navButton"
              }
              to={`/users/${user.user.id}/stats`}
            >
              Stats
            </NavLink>
            <NavLink
              onClick={toggleMenu}
              className={({ isActive }) =>
                isActive ? "activeNavButton navButton" : "navButton"
              }
              to={`/users/${user.user.id}/cols`}
            >
              Map
            </NavLink>
            <NavLink
              onClick={toggleMenu}
              className={({ isActive }) =>
                isActive ? "activeNavButton navButton" : "navButton"
              }
              to={`/users/${user.user.id}/about`}
            >
              About
            </NavLink>
            <button class="refreshActivitiesButton" onClick={refreshStravaData}>Refresh Strava data</button>
          </div>
          {/* <NavLink
              className={({ isActive }) =>
                isActive ? "activeNavButton navButton" : "navButton"
              }
              to={`/users/${user.user.id}/cols`}
            >
              Map
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "activeNavButton navButton" : "navButton"
              }
              to={`/users/${user.user.id}/stats`}
            >
              Stats
            </NavLink> */}
        </div>
      </nav>
    </>
  );
};

export default Header;
