import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/StravaLogin";
import UserMain from "./components/UserMain";
import "./App.css";
import "./assets/fonts/fonts.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import React from "react";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/users/:userId/*" element={<UserMain />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
