import "./UserMetrics.css";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const UserMetrics = ({ userColData }) => {
  const processColData = (userColData) => {
    const totalCol = userColData.length;
    const totalActivities = userColData.reduce(
      (acc, claim) => acc + claim.activity_count,
      0,
    );
    const totalColbelow500 = userColData.filter(
      (claim) => claim.col.elevation < 500,
    ).length;
    const totalColOver500 = userColData.filter(
      (claim) => claim.col.elevation > 500 && claim.col.elevation < 1000,
    ).length;
    const totalColOver1000 = userColData.filter(
      (claim) => claim.col.elevation > 1000 && claim.col.elevation < 2000,
    ).length;
    const totalColOver2000 = userColData.filter(
      (claim) => claim.col.elevation > 2000,
    ).length;
    return {
      total_col: totalCol,
      total_activities: totalActivities,
      total_col_below_500: totalColbelow500,
      total_col_over_500: totalColOver500,
      total_col_over_1000: totalColOver1000,
      total_col_over_2000: totalColOver2000,
    };
  };

  const processedColData = processColData(userColData);
  return (
    <div>
      {userColData ? (
        <Grid
          container
          className="userMetrics"
          spacing={{ xs: 3 }}
          columns={{ xs: 2, sm: 2, md: 3, lg: 6 }}
        >
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <div className="metric gray">
              <span className="metricValue xlValue">
                {processedColData.total_activities}
              </span>
              <span className="metricDescription">climbs</span>
            </div>
          </Grid>
          <Grid item xs={2} sm={1} md={1} lg={1}>
            <div className="metric black">
              <span className="metricValue xlValue">
                {processedColData.total_col}
              </span>
              <span className="metricDescription">cols climbed</span>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <div className="metric red">
              <span className="metricValue xlValue">
                {processedColData.total_col_over_2000}
              </span>
              <span className="metricDescription">cols &gt; 2000m</span>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <div className="metric yellow">
              <span className="metricValue lValue">
                {processedColData.total_col_over_1000}
              </span>
              <span className="metricDescription">cols &gt; 1000m</span>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <div className="metric green">
              <span className="metricValue mValue">
                {processedColData.total_col_over_500}
              </span>
              <span className="metricDescription">cols &gt; 500m</span>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <div className="metric blue">
              <span className="metricValue sValue">
                {processedColData.total_col_below_500}
              </span>
              <span className="metricDescription">cols &lt; 500m</span>
            </div>
          </Grid>
        </Grid>
      ) : (
        <span>Could not fetch user data...</span>
      )}
    </div>
  );
};

export default UserMetrics;
