import "./UserColPage.css";
import React, { useEffect, useState } from "react";
import MapBoxComponent from "./MapBoxComponents/MapBoxComponent";
import { useParams } from "react-router-dom";
import Loading from "./Loading";

const UserColPage = () => {
  const { userId, colId } = useParams();
  const [loading, setLoading] = useState(true);
  const [initialCol, setInitialCol] = useState(null);
  const [otherCols, setOtherCols] = useState(null);
  const [userCols, setUserCols] = useState(null);

  useEffect(() => {
    const fetchCols = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/cols`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOtherCols(
          data
            .map((col) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [col.longitude, col.latitude],
              },
              properties: {
                name: col.name,
                elevation: col.elevation,
                id: col.id,
              },
            }))
            .sort((a, b) => b.properties.elevation - a.properties.elevation),
        );
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    };

    const fetchClaims = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/cols`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setUserCols(
          data
            .map((claim) => ({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [claim.col.longitude, claim.col.latitude],
              },
              properties: {
                name: claim.col.name,
                elevation: claim.col.elevation,
                id: claim.col.id,
              },
            }))
            .sort((a, b) => b.properties.elevation - a.properties.elevation),
        );
        if (colId) {
          data.forEach((claim) => {
            if (claim.col.id === parseInt(colId)) {
              setInitialCol({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [claim.col.longitude, claim.col.latitude],
                },
                properties: {
                  name: claim.col.name,
                  elevation: claim.col.elevation,
                  id: claim.col.id,
                },
              });
            }
          });
        } else {
          setInitialCol({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [data[0].col.longitude, data[0].col.latitude],
            },
            properties: {
              name: data[0].col.name,
              elevation: data[0].col.elevation,
              id: data[0].col.id,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    };

    if (!otherCols) {
      fetchCols();
    }
    if (!userCols) {
      fetchClaims();
    }

    setLoading(!(initialCol && otherCols && userCols && userId));
  }, [userId, colId, initialCol, otherCols, userCols]);
  return (
    <>
      {!loading ? (
        <MapBoxComponent
          highlightedCols={userCols}
          otherCols={otherCols}
          initialCol={initialCol}
          userId={userId}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default UserColPage;
