import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState, useGlobalDispatch } from "../GlobalStateContext";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import UserDataPage from "./UserDataPage";
import Header from "./Header";
import JoinClub from "./JoinClub";
import Loading from "./Loading";
import UserColPage from "./UserColPage";
import About from "./About";
import "./UserMain.css";

const UserMain = () => {
  const { userId } = useParams();
  const { user } = useGlobalState();
  const dispatch = useGlobalDispatch();

  const [userColData, setUserColData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasJoinedClub, setHasJoinedClub] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const updateUser = (user) => {
      dispatch({ type: "SET_USER", payload: user });
    };

    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        updateUser(data);
        setHasJoinedClub(data.user.has_joined_club);
      } catch (error) {
        navigate("/");
        const userId = Cookies.get("colquest_user_id");
        if (userId) {
          Cookies.remove("colquest_user_id", {path: "/", domain: ".colquest.cc"});
          Cookies.remove("colquest_user_id", {path: "/", domain: "localhost"});
        }
      }
    };

    const fetchUserColData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/users/${userId}/cols`,
      );
      const data = await response.json();
      setUserColData(data);
    };

    if (!user) {
      setIsLoading(true);
    } else {
      document.title = user.user.first_name + "'s ColQuest";
      if (hasJoinedClub === false) {
        setIsLoading(false);
      } else {
        if (userColData) {
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      }
    }

    if (user && hasJoinedClub && !userColData) {
      fetchUserColData();
    }
    const intervalId = setInterval(() => {
      if (hasJoinedClub === false) {
        fetchUserData();
      }
      if (hasJoinedClub === true && user.user.pending_activity_ingestion) {
        fetchUserData();
        fetchUserColData();
      }
      if (
        hasJoinedClub === true &&
        !user.user.pending_activity_ingestion &&
        userColData
      ) {
        clearInterval(intervalId);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [isLoading, userId, hasJoinedClub, user, userColData, dispatch, navigate]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : hasJoinedClub === false ? (
        <JoinClub />
      ) : (
        <div className="page">
          <Header />
          <Routes>
            <Route
              path="/*"
              element={<UserColPage />} />
            />
            <Route
              path="/stats"
              element={<UserDataPage userColData={userColData} />}
            />
            <Route path="/cols" element={<UserColPage />} />
            <Route path="/cols/:colId" element={<UserColPage />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default UserMain;
